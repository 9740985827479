<template>
  <div class="modal deposit-modal">
    <div class="overlay" @click="closeDepositModal" />
    <div class="wrapper">
      <div class="container">
        <img
          class="close"
          src="./../assets/img/close.svg"
          @click="closeDepositModal"
          alt="Close"
        />
        <div class="modal-header">
          <h2 class="modal-title">{{ $t("Top up balance") }}</h2>
        </div>
        <div class="modal-content">
          <div class="form-fields">
            <label>
              <input
                v-model="name"
                type="text"
                :placeholder="$t('First name')"
              />
            </label>
            <label>
              <input
                v-model="surname"
                type="text"
                :placeholder="$t('Last Name')"
              />
            </label>
            <label>
              <input v-model="email" type="email" :placeholder="$t('Email')" />
            </label>
            <label for="phone-input" v-if="countryCode && countryOptionsIso">
              <vue-tel-input
                :autoDefaultCountry="false"
                :autoFormat="false"
                id="phone-input"
                v-model.trim="phone"
                :defaultCountry="countryCode"
                @country-changed="phoneCountryChanged"
                :onlyCountries="countryOptionsIso"
                @on-input="phoneDataUpdate"
                :inputOptions="{
                  showDialCode: true,
                  styleClasses: 'input-phone',
                }"
              />
            </label>
            <label class="country-select-container">
              <div class="select-wrapper">
                <select v-model="countryId" :class="{ empty: !countryId }">
                  <option value="" selected>{{ $t("Country") }}</option>
                  <option
                    v-for="option in countryOptions"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.title }}
                  </option>
                </select>
              </div>
            </label>
            <label>
              <input type="text" :placeholder="$t('City')" v-model="city" />
            </label>
            <label>
              <input
                type="text"
                :placeholder="$t('Address')"
                v-model="address"
              />
            </label>
            <label>
              <input type="text" :placeholder="$t('Post Code')" v-model="zip" />
            </label>
            <label>
              <input
                class="amount"
                type="text"
                disabled
                :value="depositAmount + ' ' + depositCurrency"
              />
            </label>
            <div v-if="error" class="error-desc red">
              {{ error }}
            </div>
          </div>

          <div class="modal-footer">
            <div class="checkbox-wrapper">
              <BaseCheckbox
                id="termsCheckbox"
                name="terms"
                v-model="agreedToTerms"
              >
                {{ $t("By depositing you agree to our") }}
                <a href="#" @click="goToPage('terms')">{{ $t("terms") }}</a>
                {{ $t("and") }}
                <a href="#" @click="goToPage('privacy')">{{
                  $t("privacy policy")
                }}</a
                >.
              </BaseCheckbox>
            </div>
            <button
              class="pay-button button"
              :disabled="!requiredFieldsAreFilled || loaderIsVisible"
              @click="submitDeposit"
            >
              <template v-if="loaderIsVisible">
                <Loader />
              </template>
              <template v-else>
                {{ $t("Deposit") }}
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { parsePhoneNumber } from "libphonenumber-js";

export default {
  name: "DepositModal",
  components: {},
  props: {
    loaderIsVisible: {
      type: Boolean,
      required: true,
    },
    depositAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    depositCurrency: {
      type: String,
      default: "€",
    },
    paymentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      phone: "",
      countryCode: "",
      phoneFull: "",
      countryOptions: [],
      countryOptionsIso: [],
      address: "",
      city: "",
      zip: "",
      countryId: "",
      error: "",
      agreedToTerms: false,
    };
  },
  computed: {
    ...mapGetters("user", ["userData"]),
    ...mapGetters("app", ["currencySymbol", "currencyCode"]),
    requiredFieldsAreFilled() {
      return (
        this.name &&
        this.surname &&
        this.phone &&
        this.phoneFull &&
        this.email &&
        this.countryId &&
        this.city &&
        this.address &&
        this.zip &&
        this.agreedToTerms
      );
    },
  },
  mounted() {
    this.getCountries();
    this.initializeUserData();
  },
  methods: {
    ...mapActions("modals", ["closeDepositModal"]),
    initializeUserData() {
      if (this.userData) {
        this.email = this.userData.email;
        this.name = this.userData.name;
        this.surname = this.userData.surname;
        this.phone = this.userData.phone;
        if (this.phone) {
          if (!this.phone.startsWith("+")) {
            this.phone = "+" + this.phone;
          }
          // Parse the phone number to get country code and national number
          const parsedPhone = parsePhoneNumber(this.phone);
          if (parsedPhone) {
            this.countryCode =
              parsedPhone.country || this.userData.phoneCountryCode;
            this.updatePhoneNumber(
              parsedPhone.countryCallingCode,
              parsedPhone.nationalNumber
            );
          } else {
            // If parsing fails, use the original phone number
            this.countryCode = this.userData.phoneCountryCode;
            this.updatePhoneNumber("", this.phone);
          }
        } else {
          this.countryCode = this.userData.phoneCountryCode;
        }
        this.countryCode = this.userData.phoneCountryCode;
        this.address = this.userData.address;
        this.city = this.userData.city;
        this.zip = this.userData.zip;
        this.countryId = this.userData.country || "";
      }
    },
    goToPage(id) {
      this.closeDepositModal();
      this.$emit("goToPage", id);
    },
    phoneCountryChanged(value) {
      this.countryCode = value?.iso2;
      const dialCode = value?.dialCode || "";

      if (this.phoneObject && this.phoneObject.nationalNumber) {
        // Remove the country code from the national number
        const nationalNumber = this.phoneObject.nationalNumber.replace(
          /^\+?[0-9]+\s*/,
          ""
        );
        this.updatePhoneNumber(dialCode, nationalNumber);
      } else if (this.phone) {
        // If phoneObject is empty but we have a phone number, try to extract the national number
        const nationalNumber = this.phone.replace(/^\+?[0-9]+\s*/, "");
        this.updatePhoneNumber(dialCode, nationalNumber);
      } else {
        // If we don't have any phone number, just set the dial code
        this.updatePhoneNumber(dialCode, "");
      }
    },

    phoneDataUpdate(number, phoneObject) {
      if (
        phoneObject &&
        phoneObject.country &&
        phoneObject.countryCallingCode &&
        phoneObject.nationalNumber
      ) {
        this.phoneObject = phoneObject;
        this.updatePhoneNumber(
          phoneObject.countryCallingCode,
          phoneObject.nationalNumber
        );
        this.countryCode = phoneObject.country; // ISO country code
      }
    },

    updatePhoneNumber(dialCode, nationalNumber) {
      this.phone = `+${dialCode} ${nationalNumber}`.trim();
      this.phoneFull = `${dialCode} ${nationalNumber}`;
      this.phoneE164 = `+${dialCode}${nationalNumber}`;
    },
    async getCountries() {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API}countries`
        );
        this.countryOptions = response.data.payload;
        this.countryOptionsIso = response.data.payload.map((obj) => obj.iso);
        if (!this.countryCode) {
          this.countryCode = this.countryOptionsIso[0];
        }
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      }
    },
    async submitDeposit() {
      if (!this.requiredFieldsAreFilled) return;
      if (!this.agreedToTerms) {
        this.$toast.warning(
          this.$t("You must agree to the terms and conditions")
        );
        return;
      }
      const depositData = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phoneFull,
        country: this.countryId,
        city: this.city,
        address: this.address,
        postCode: this.zip,
        amount: this.depositAmount,
        paymentType: this.paymentType,
        currency: this.currencyCode,
      };

      try {
        const response = await this.$http.post(
          `${process.env.VUE_APP_API}deposit/checkout`,
          depositData
        );
        if (response.data.status === "ERROR") {
          this.$toast.error(response.data.message);
          this.$router.push({ name: "PaymentFailPage" });
        } else {
          window.location = response.data.redirect_url;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 458px;
  border-radius: 12px;
  background: #161c2a;
  position: relative;
}

.wrapper .container {
  position: relative;
  padding: 44px 10px 24px;
  flex-direction: column;
}

.close {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.modal-header {
  margin-bottom: 24px;
  margin-left: 8px;
}

.modal-title {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: white;
}

.checkbox-wrapper {
}

.modal-footer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 24px;
}

.pay-button {
  max-width: 170px;
}

.amount {
  text-align: center;
  font-size: 32px;
}
</style>
