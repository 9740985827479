import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import axios from "axios";
import FloatingVue from "floating-vue";
import BaseCheckbox from "./components/common/BaseCheckout.vue";
const app = createApp(App);
app.component('BaseCheckbox', BaseCheckbox);

import "./assets/css/style.scss";
import "promise-polyfill/src/polyfill";
import "floating-vue/dist/style.css";

import Vue3Toastify, { toast } from "vue3-toastify";

// Use the plugin
app.use(Vue3Toastify, {
  autoClose: 3000,
  clearOnUrlChange: false,
});

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
app.use(VueTelInput);

// Set up global $toast property
app.config.globalProperties.$toast = {
  info: (message, options) => toast.info(message, options),
  success: (message, options) => toast.success(message, options),
  warning: (message, options) => toast.warning(message, options),
  error: (message, options) => toast.error(message, options),
  // You can add other methods as needed
  default: (message, options) => toast(message, options),
};

// Add Axios to the global properties
app.config.globalProperties.$http = axios;
app.config.globalProperties.$http.defaults.withCredentials = true;

app.use(FloatingVue);
app.use(router);
app.use(store);
app.use(i18n);

// Mount the app
app.mount("#app");
