<template>
  <div class="modal reg-modal">
    <div class="overlay" @click="$emit('closeSignUp')" />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img
              class="close"
              src="./../assets/img/close.svg"
              @click="$emit('closeSignUp')"
            />
            <div class="modal-main">
              <div class="form-fields register-content">
                <div class="form-fields-wrapper">
                  <div class="modal-title">
                    {{ $t("Sign Up") }}
                  </div>
                  <label>
                    <input
                      v-model="name"
                      type="text"
                      :placeholder="$t('First Name')"
                    />
                  </label>
                  <label>
                    <input
                      v-model="surname"
                      type="text"
                      :placeholder="$t('Last Name')"
                    />
                  </label>
                  <label>
                    <input
                      v-model="email"
                      type="email"
                      :placeholder="$t('Email')"
                    />
                  </label>
                  <label
                    for="phone-input"
                    v-if="countryCode && countryOptionsIso"
                  >
                    <vue-tel-input
                      :autoDefaultCountry="false"
                      :autoFormat="false"
                      id="phone-input"
                      v-model.trim="phone"
                      :defaultCountry="defaultCountry"
                      @country-changed="phoneCountryChanged"
                      :onlyCountries="countryOptionsIso"
                      @on-input="phoneDataUpdate"
                      :inputOptions="{
                        showDialCode: true,
                        placeholder: $t(`Phone`),
                        styleClasses: 'input-phone',
                      }"
                    />
                  </label>
                  <label>
                    <input
                      v-model="pass"
                      type="password"
                      :placeholder="$t('Password')"
                    />
                  </label>
                  <label>
                    <input
                      v-model="passConfirm"
                      type="password"
                      :placeholder="$t('Confirm Password')"
                    />
                  </label>

                  <button
                    class="sign-up-button"
                    :disabled="!requiredRegisterFieldsAreFilled"
                    @click="submitRegister"
                  >
                    <div v-if="loaderIsVisible" class="loader-container">
                      <div class="loader"></div>
                    </div>
                    <span v-else>{{ $t("Sign up") }}</span>
                  </button>
                  <Transition>
                    <div v-if="error" class="error-desc desc red">
                      {{ error }}
                    </div>
                  </Transition>
                </div>
              </div>
            </div>

            <div class="checkbox-wrapper">
              <BaseCheckbox
                id="termsCheckbox"
                name="terms"
                v-model="agreedToTerms"
              >
                {{ $t("By signing up you agree to our") }}
                <a href="#" @click="goToPage('terms')">{{ $t("terms") }}</a>
                {{ $t("and") }}
                <a href="#" @click="goToPage('privacy')">{{
                  $t("privacy policy")
                }}</a
                >.
              </BaseCheckbox>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-bottom">
        <div class="modal-bottom-content">
          <div class="desc">
            {{ $t("Already have an account?") }}
          </div>
          <a class="modal-bottom-sign-up-button" @click="openSignInModal">
            {{ $t("Sign in") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationModal",
  components: {},
  props: {
    error: {
      type: String,
      required: true,
    },
    loaderIsVisible: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      email: "",
      pass: "",
      name: "",
      surname: "",
      phone: "",
      passConfirm: "",
      agreedToTerms: false,
      countryCode: "",
      phoneFull: "",
      phoneE164: "",
      countryOptions: [],
      countryOptionsIso: [],
    };
  },
  computed: {
    defaultCountry() {
      return this.countryCode || this.countryOptionsIso[0] || "";
    },
    requiredRegisterFieldsAreFilled() {
      return (
        this.name &&
        this.surname &&
        this.phone &&
        this.phoneFull &&
        this.email &&
        this.pass &&
        this.passConfirm &&
        this.agreedToTerms
      );
    },
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    goToPage(id) {
      this.$emit("goToPage", id);
    },
    phoneCountryChanged(value) {
      this.countryCode = value?.iso2;
      const dialCode = value?.dialCode || "";

      if (this.phoneObject && this.phoneObject.nationalNumber) {
        // Remove the country code from the national number
        const nationalNumber = this.phoneObject.nationalNumber.replace(
          /^\+?[0-9]+\s*/,
          ""
        );
        this.updatePhoneNumber(dialCode, nationalNumber);
      } else if (this.phone) {
        // If phoneObject is empty but we have a phone number, try to extract the national number
        const nationalNumber = this.phone.replace(/^\+?[0-9]+\s*/, "");
        this.updatePhoneNumber(dialCode, nationalNumber);
      } else {
        // If we don't have any phone number, just set the dial code
        this.updatePhoneNumber(dialCode, "");
      }
    },

    phoneDataUpdate(number, phoneObject) {
      if (
        phoneObject &&
        phoneObject.country &&
        phoneObject.countryCallingCode &&
        phoneObject.nationalNumber
      ) {
        this.phoneObject = phoneObject;
        this.updatePhoneNumber(
          phoneObject.countryCallingCode,
          phoneObject.nationalNumber
        );
        this.countryCode = phoneObject.country; // ISO country code
      }
    },

    updatePhoneNumber(dialCode, nationalNumber) {
      this.phone = `+${dialCode} ${nationalNumber}`.trim();
      this.phoneFull = `${dialCode} ${nationalNumber}`;
      this.phoneE164 = `+${dialCode}${nationalNumber}`;
    },
    getCountries() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map((obj) => obj.iso);
          if (!this.countryCode) {
            this.countryCode = this.countryOptionsIso[0];
          }
        })
        .catch(() => {});
    },

    openSignInModal() {
      this.$emit("openSignIn");
    },
    submitRegister() {
      if (!this.agreedToTerms) {
        this.$toast.warning(this.$t("You must agree to the terms and conditions"));
        return;
      }
      let regData = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phoneFull,
        countryCallingCode: this.countryCode,
        phoneE164: this.phoneE164,
        password: this.pass,
        passConfirm: this.passConfirm,
      };
      this.$emit("registration", regData);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
}

.modal .wrapper {
}

.modal .form-wrapper {
  display: flex;
  flex-direction: column;
}

.modal-main {
  max-width: 298px;
  margin: 0 auto;
  width: 100%;
}

.modal .wrapper .container {
  background: #161c2a;
  border-radius: 12px;
  padding: 44px 10px 25px;
  margin-bottom: 12px;
  justify-content: center;
}

.modal .form {
}

.modal .form-fields label {
  margin-top: 38px;
}

.modal .form-fields label + label {
  margin-top: 14px;
}

.sign-up-button {
  margin-top: 24px;
  margin-bottom: 16px;
}

.modal-bottom {
  padding: 26px 24px;
  background: linear-gradient(
    180deg,
    rgba(255, 230, 68, 0.1) 0%,
    rgba(0, 179, 48, 0.1) 100%
  );
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  &::before {
    content: "";
    position: absolute;
    inset: -1px;
    z-index: -1;
    padding: 2px;
    border-radius: 14px;
    background-image: linear-gradient(180deg, #ffe644 0%, #00b230 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }

  .modal-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .desc {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }

    .link {
      color: #00b230;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.checkbox-wrapper {
  margin-top: 16px;
}

.resend-button {
  width: 100%;
  margin-top: 16px;
}
</style>
